<template>
  <m-container>
    <h2 class="mb-5">{{ exam.title }}</h2>
    <h4 class="mb-2">{{ !$language ? "Instruction" : "নির্দেশনা" }}</h4>
    <p class="mb-3">{{ exam.instruction }}</p>
    <v-row justify="center" align="center" class="my-6">
      <v-divider style="max-width: 350px"></v-divider>
    </v-row>
    <v-row>
      <v-col>
        <h4 class="mb-2">{{ !$language ? "Points" : "পয়েন্ট" }}</h4>
        <p>{{ exam.points }}</p>
      </v-col>
      <v-col>
        <h4 class="mb-2">{{ !$language ? "Duration" : "সময়সীমা" }}</h4>
        <p>{{ exam.duration }} {{ !$language ? "mins" : "মিনিট" }}</p>
      </v-col>
    </v-row>

    <!--    <h3>Categories</h3>-->
  </m-container>
</template>

<script>
export default {
  name: "ExamDetailsP",
  components: {},
  props: {
    exam: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
