var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "Exam Details" : "পরীক্ষার বিস্তারিত",
        },
      }),
      _c(
        "m-container",
        [
          _vm.networkError
            ? _c("no-content-component", {
                attrs: { message: _vm.examDataFetchFailMessage },
              })
            : _c(
                "lazy-load",
                { attrs: { item: _vm.exam } },
                [
                  _c(
                    "m-card",
                    { staticClass: "m-light-card" },
                    [
                      _c(
                        "m-card-text",
                        [
                          _c("exam-details-p", { attrs: { exam: _vm.exam } }),
                          _c(
                            "v-row",
                            {
                              staticClass: "mr-4",
                              attrs: {
                                justify: _vm.$vuetify.breakpoint.smAndDown
                                  ? "center"
                                  : "end",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    rounded: "",
                                    depressed: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.startExam },
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-3" }, [
                                    _vm._v("alarm"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.$language
                                          ? "Start Exam"
                                          : "পরীক্ষা দাও"
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }