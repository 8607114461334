var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("h2", { staticClass: "mb-5" }, [_vm._v(_vm._s(_vm.exam.title))]),
      _c("h4", { staticClass: "mb-2" }, [
        _vm._v(_vm._s(!_vm.$language ? "Instruction" : "নির্দেশনা")),
      ]),
      _c("p", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.exam.instruction))]),
      _c(
        "v-row",
        { staticClass: "my-6", attrs: { justify: "center", align: "center" } },
        [_c("v-divider", { staticStyle: { "max-width": "350px" } })],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h4", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(!_vm.$language ? "Points" : "পয়েন্ট")),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.exam.points))]),
          ]),
          _c("v-col", [
            _c("h4", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(!_vm.$language ? "Duration" : "সময়সীমা")),
            ]),
            _c("p", [
              _vm._v(
                _vm._s(_vm.exam.duration) +
                  " " +
                  _vm._s(!_vm.$language ? "mins" : "মিনিট")
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }