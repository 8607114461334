<template>
  <m-container>
    <page-title
      previous-location
      :page-title="!$language ? 'Exam Details' : 'পরীক্ষার বিস্তারিত'"
    ></page-title>
    <m-container>
      <no-content-component
        v-if="networkError"
        :message="examDataFetchFailMessage"
      ></no-content-component>
      <lazy-load v-else :item="exam">
        <m-card class="m-light-card">
          <m-card-text>
            <exam-details-p :exam="exam"></exam-details-p>
            <v-row
              class="mr-4"
              :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
            >
              <v-btn
                rounded
                depressed
                color="primary"
                class="ml-2"
                @click="startExam"
              >
                <v-icon class="mr-3">alarm</v-icon>
                {{ !$language ? "Start Exam" : "পরীক্ষা দাও" }}
              </v-btn>
            </v-row>
          </m-card-text>
        </m-card>
      </lazy-load>
    </m-container>
  </m-container>
</template>

<script>
import ExamDetailsP from "#ef/exam/components/ExamDetailsP";
import NoContentComponent from "/global/components/NoContentComponent";
import { ExamProvider } from "@ef/modules/exam/mixins";

export default {
  name: "ExamDetails",
  components: { ExamDetailsP, NoContentComponent },
  mixins: [ExamProvider],
  props: {
    examId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      networkError: false,
      loadingExam: false,
      studentHasAttendedExam: true,
      examNotAttendableToday: true
    };
  },
  computed: {
    examDataFetchFailMessage() {
      return this.$language
        ? "পরীক্ষার তথ্য এই মুহূর্তে এনে দেয়া সম্ভব হচ্ছে না"
        : "Exam details are currently unavailable";
    }
  },
  async created() {
    await this.checkIfAttendedExam();
    let examInfo = await this.getExamDetails();
    // let serverDateTime = await this.$store.dispatch(
    //   "studentExam/getCurrentTime"
    // );
    // this.examNotAttendableToday =
    //   examInfo.start_date > serverDateTime.current_time ||
    //   examInfo.end_date < serverDateTime.current_time;
  },
  methods: {
    async checkIfAttendedExam() {
      try {
        this.loadingExam = true;
        let content = await this.$store.dispatch(
          "studentExam/studentHasAttendedExam",
          {
            examId: this.examId,
            batchId: this.$ielts_batch_id
          }
        );
        this.studentHasAttendedExam = content.has_attended;
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async startExam() {
      if (this.studentHasAttendedExam) {
        this.$root.$emit("alert", [
          "Attention",
          "You have already attended this exam"
        ]);
        return;
      }
      try {
        this.loadingExam = true;
        const { student_exam_id: studentExamId } = await this.$store.dispatch(
          "studentExam/start",
          {
            examId: this.examId,
            batchId: this.$ielts_batch_id
          }
        );
        await this.$router.push({
          name: "studentsAppearExamRoutes",
          params: { appear: "appear", testId: studentExamId }
        });
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      }
    }
  }
};
</script>

<style></style>
